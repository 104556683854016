* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  color: #fff;
  font-family: Tahoma;
  scroll-margin-top: 100px;
}

body {
  background: #000;
  color: #000;
}

.hover-crd {
  text-align: center;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: #ff0000;
  border-radius: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@font-face {
  font-family: 'yellowtail';
  src: url('../public/fonts/Yellowtail-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tahoma';
  src: url('../public/fonts/tahoma.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tahomabold';
  src: url('../public/fonts/tahomabd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  background: black;
}

/* nav css */

img.menues {
  width: 45px;
}

nav {
  width: 100%;
  position: fixed !important;
  left: 0;
  top: 0;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.5);
  z-index: 10;
  background: black;
}

div#navbarTogglerDemo02 {
  justify-content: end;
}

/* p{
  font-size: 16px;
} */
.navbar-brand {
  font-weight: bold;
  color: white;
  font-size: 50px;
  background: black !important;
}

.nav-link {
  color: white !important;

  /* border-bottom:  1px solid var(--primary) transparent !important ; */
}

li.nav-item {
  margin-left: 30px;
  letter-spacing: 0px;
  font-size: 16px;
}

.nav-link:hover {
  border-bottom: 1px solid white !important;
}

.navbar-dark .navbar-brand {
  color: #fff;
  font-size: 50px;
}

.navbar-collapse {
  transition: max-height 0.5s ease;
}

.navbar-collapse.show {
  max-height: 500px;
}

/* video css */
.video-background {
  background: gray;
  padding: 3rem 0rem;
  position: relative;
  width: 100%;
}

.margin-5 {
  margin-top: 6rem;
}

/* about */
#About {
  /* color: white;
  text-align: center;
  color: darkgray;
  padding: 10rem 0rem; */
  color: white;
  text-align: center;
  color: darkgray;
  /* padding: 5rem 0rem; */
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.abt-list {
  color: white;
  text-align: center;
  /* margin-top: 60px; */
}

.list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* members css */
.para {
  font-size: 12px;
  text-align: center;
}

img.mem-img {
  width: 100%;
  max-width: 370px;
  height: 350px;
}
.mute_btn{
  position: absolute;
  top: 85%;
  right: 5%;
  padding: 5px 20px;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: black;
  color: white;
}

button.submit_btn {
  padding: 5px 20px;
  border: none;
  outline: none;
  border-radius: 25px;
  background-color: #673ab7;
  color: white;
}
button.submit_btn:hover {
  background-color: #e91e63;
  box-shadow: 0px 0px 5px;
}

button.submit_btn:disabled {
  cursor: not-allowed !important;
}
ul.control-dots{
  display: none !important;
}
.carousel .thumbs-wrapper {
  margin: 0px !important;
  padding: 20px !important;
  overflow: hidden !important;
  background: black !important;
}
.name-div {
  padding: 30px 0px;
}

.members-text {
  padding-top: 30px;
}

.mem-para {
  padding: 68px;
  letter-spacing: 2px;
  line-height: 22px;
  font-size: 14px;
}

.person {
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
}

p.gaps {
  font-size: 14px;
}

.person-description {
  text-align: left;
  padding: 1rem 1rem;
}

img.about-img {
  width: 100%;
  max-width: 320px;
}

/* Animation css */

.animation-div h2 {
  text-align: center;
  padding-bottom: 30px;
}

.close-carousel {
  position: absolute;
  top: -7px;
  right: 10px;
  background: transparent;
  border: none;
  color: #000 !important;
  font-size: 30px;
  cursor: pointer;
  z-index: 1100;
}

.MuiBox-root.css-vtxhf1 {
  background: #454545;
}

.carousel-control-prev {
  left: -46px;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 11%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}

.carousel-control-next {
  right: -42px;
}


/* Amusement css */

.amusement-div h2 {
  text-align: center;
  padding: 30px 0px;
}

/* Animatronics css */
.animatronics-div h2 {
  text-align: center;
  padding: 30px 0px;
}

/* .modal-box.MuiBox-root.css-w082rn {
  background: #454545;
} */

/* footer css */
.btn-div {
  text-align: center;

}

.contact-form {
  background: #bcbcbc;
  padding: 3rem 2rem 3rem 2rem;
}

.hr-lines {
  position: relative;
  max-width: 500px;
  margin: 30px auto;
  text-align: center;
}

.hr-lines:before {
  content: "";
  height: 1px;
  width: 169px;
  background: #fff;
  display: block;
  position: absolute;
  top: 30%;
  left: 0;
}

.hr-lines:after {
  content: "";
  height: 1px;
  width: 169px;
  background: #fff;
  display: block;
  position: absolute;
  top: 30%;
  right: 0;
}

.hr-lines1 {
  position: relative;
  max-width: 500px;
  margin: 30px auto;
  text-align: center;
}

.hr-lines1:before {
  content: "";
  height: 1px;
  width: 150px;
  background: #fff;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.hr-lines1:after {
  content: "";
  height: 1px;
  width: 150px;
  background: #fff;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

.hr-lines {
  position: relative;
  max-width: 500px;
  margin: 30px auto;
  text-align: center;
}

.hr-lines2:before {
  content: "";
  height: 1px;
  width: 246px;
  background: #fff;
  display: block;
  position: absolute;
  top: 11%;
  left: 0;
}

.hr-lines2:after {
  content: "";
  height: 1px;
  width: 246px;
  background: #fff;
  display: block;
  position: absolute;
  top: 11%;
  right: 0;
}

.video_css {
  width: 100%;
  height: 450px;
}

#backtotop {
  position: fixed;
  bottom: 15px;
  right: 40px;
  z-index: 9999;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 28px;
  background: #eaefff;
  color: #ff0000;
  cursor: pointer;
  border: 1px solid;
  border-radius: 50%;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  font-size: 28px;
}

.sys_padd {
  padding: 4rem 0;
}
.address-form {
  padding: 40px 0px;
}
.address-details {
  height: 200px;
  display: grid;
  align-items: flex-start;
}
@media screen and (max-width: 1024px){
  button.mute_btn {
    right: 1.5%;
}
}

@media only screen and (max-width: 768px) {
  .mute_btn {
    top: 87%;
    right: 2%;
  }
}

@media screen and (max-width: 600px) {

  .hr-lines:before,
  .hr-lines:after {
    display: none;
  }

  .hr-lines1:before,
  .hr-lines1:after {
    display: none;
  }

  .hr-lines2:before,
  .hr-lines2:after {
    display: none;
  }

  .video_css {
    width: 100%;
    height: auto;
  }
}

p.MuiTypography-root.MuiTypography-body2.css-r40f8v-MuiTypography-root {
  background: #000;
  color: #fff;
  text-align: center;
  padding-top: 20px;

}


@media screen and (max-width: 600px) {
  .MuiBox-root.css-vtxhf1 {
    background: #454545;
    width: 93%;
  }

  .carousel-control-next {
    right: -34px;
  }

  .carousel-control-prev {
    left: -34px;
  }

  .mem-para {
    padding: 25px;
    letter-spacing: 2px;
    line-height: 21px;
    font-size: 14px;
  }

  .sys_padd {
    padding: 1rem 0;
  }

  .hr-lines1 {
    margin: 10px auto;
  }

  #About {
    height: 81vh;
  }

  .name-div {
    padding: 1rem 0;
  }

  .animation-div h2 {
    padding-bottom: 0px;
  }
}